$line-height-sm: px-to-rem(16);
$line-height-md: px-to-rem(18);
$card-max-height: 556px;

.product-card {
  box-sizing: border-box;
  padding-bottom: 8px;
  text-align: left;
  border-bottom: 1px solid var(--color-xxl-light-gray);

  @include MQ-up("laptop") {
    position: relative;
    max-width: 324px;
    height: $card-max-height;
    padding-bottom: 0;
    border-bottom: none;
  }

  a {
    position: relative;
    display: grid;
    grid-column-gap: var(--space-mini);
    grid-template-rows: max-content 1fr;
    grid-template-columns: 116px 1fr;
    box-sizing: border-box;
    text-decoration: none;

    @include MQ-up("laptop") {
      display: block;
      height: 100%;
    }
  }

  &.product-card__sliders--mobile {
    padding-bottom: 0;
    border-bottom: none;

    @include MQ-down("laptop") {
      a {
        display: block;
        height: 100%;
      }

      .product-card__header {
        max-width: unset;
        height: 188px;
        figure {
          display: flex;
          align-items: center;
          width: calc(100% - var(--space-mini) * 2);
          max-width: 156px;
          height: 100%;
          max-height: 156px;
        }
      }

      .product-card__additional-info {
        margin-bottom: 0;
        .ribbon {
          display: none;
        }
      }

      .product-card__details {
        gap: 28px;

        h3 {
          -webkit-line-clamp: 1;
        }
      }

      .ribbon {
        position: relative;
        display: flex;
      }
    }
  }

  .ribbon {
    $ribbon-max-height: 42px;
    $ribbon-max-height-mobile: 24px;

    height: $ribbon-max-height-mobile;

    @include MQ-up("laptop") {
      height: $ribbon-max-height;
    }

    &::before {
      border-top-color: $color-xxl-green;
      @include ribbon-left($ribbon-max-height-mobile);

      @include MQ-up("laptop") {
        @include ribbon-left($ribbon-max-height);
      }
    }

    .ribbon__content {
      padding: 0 var(--space-mini) 0 var(--space-micro);
      font-size: $font-size-tiny;

      @include MQ-up("laptop") {
        padding: 0 var(--space-regular) 0 var(--space-small-regular);
        font-size: px-to-rem(15);
      }
    }
  }
}
$colorAndRatingHeightMobile: var(--space-mini);
$colorAndRatingHeightDesktop: 10px;
$colorAndRatingTextSizeMobile: px-to-rem(10);
$colorAndRatingTextSizeDesktop: var(--space-small-regular);

.product-card__header {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 1/3;
  max-width: 116px;
  height: 140px;
  background-color: var(--color-xxl-light-gray);

  @include MQ-up("laptop") {
    max-width: initial;
    height: 366px;
  }

  figure {
    position: relative;
    display: block;
    align-self: center;
    width: 100px;
    height: 100px;
    margin: auto;
    overflow: hidden;
    text-align: center;

    @include MQ-up("laptop") {
      width: calc(100% - 32px);
      height: auto;
    }
  }

  picture {
    display: inline-block;
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    font-size: $font-size-small;
    text-decoration: none;
  }
}

.product-card__splash {
  display: grid;
  grid-column-gap: var(--space-small-regular);
  grid-template-columns: minmax(min-content, 100px) 1fr;

  @include MQ-up("laptop") {
    grid-template-columns: minmax(min-content, 185px) 1fr;
  }

  .product-card__splash-info {
    padding: 6px 0 6px var(--space-mini);
    color: var(--color-xxl-web-black);
    font-size: $font-size-tiny;
    font-family: var(--font-family-medium);

    @include MQ-up("laptop") {
      padding: var(--space-small-regular) 0 var(--space-small-regular)
        var(--space-regular);
      font-size: $font-size-small-regular;
    }

    &--reward {
      color: var(--color-xxl-red);
    }
  }

  .ribbon {
    position: absolute;
    top: 0;
    right: 0;
    grid-column: 2/3;

    @include MQ-down("laptop") {
      display: none;
    }

    @include MQ-up("laptop") {
      position: relative;
    }
  }
}

.product-card__label {
  display: flex;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
}

.product-card__additional-info {
  display: flex;
  gap: var(--space-micro);
  align-items: flex-start;
  margin-bottom: var(--space-micro);

  @include MQ-up("laptop") {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;
    padding: var(--space-small-regular) 0;

    .ribbon {
      display: none;
    }
  }
}

.product-card__color-and-rating-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: var(--space-micro);
  align-items: center;
  justify-content: space-between;
  @include MQ-down("laptop") {
    margin: var(--space-mini) 0 6px;
  }
}

.product-card__color-info {
  display: flex;
  gap: var(--space-micro);
  align-items: center;
  height: $colorAndRatingHeightMobile;

  @include MQ-up("laptop") {
    height: $colorAndRatingHeightDesktop;
  }

  span {
    display: inline-block;
    box-sizing: border-box;
    width: var(--space-mini);
    height: var(--space-mini);
    border-radius: 50%;

    &.product-card__color--white {
      border: 1px solid var(--color-xxl-web-gray);
    }

    @include MQ-up("laptop") {
      width: 10px;
      height: 10px;
    }
  }

  p {
    margin: 0 0 0 1px;
    font-size: $colorAndRatingTextSizeMobile;
    line-height: 1;
    @include MQ-up("laptop") {
      font-size: $colorAndRatingTextSizeDesktop;
    }
  }
}

.product-card__rating {
  display: flex;
  grid-column: 2/3;
  align-items: center;
  height: $colorAndRatingHeightMobile;

  @include MQ-up("laptop") {
    height: $colorAndRatingHeightDesktop;
  }
}

.product-card__reviews-number {
  display: inline;
  margin: 0;
  font-size: $colorAndRatingTextSizeMobile;
  line-height: 1;
  @include MQ-up("laptop") {
    font-size: $colorAndRatingTextSizeDesktop;
  }
}

.product-card__details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include MQ-up("laptop") {
    gap: 28px;
  }
}

.product-card__info-wrapper {
  h3,
  p {
    margin: 0;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;

    @include MQ-up("laptop") {
      line-height: $line-height-md;
    }
  }

  h3 {
    display: -webkit-box;
    max-height: calc(2 * #{$line-height-sm});
    margin-bottom: var(--space-micro);
    color: var(--color-xxl-web-black);
    font-size: px-to-rem(12);
    font-family: var(--font-family-bold);
    text-transform: capitalize;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include MQ-up("laptop") {
      height: calc(1 * #{$line-height-md});
      font-size: px-to-rem(15);
      -webkit-line-clamp: 1;
    }
  }

  p {
    display: -webkit-box;
    height: calc(2 * #{$line-height-sm});
    color: var(--color-xxl-web-gray);
    font-size: px-to-rem(12);
    line-height: $line-height-sm;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include MQ-up("laptop") {
      height: calc(2 * #{$line-height-md});
      font-size: px-to-rem(15);
      line-height: $line-height-md;
    }
  }
}

.product-card__price-wrapper {
  display: flex;
  gap: var(--space-regular);
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 44px;
}

.product-card__previous-price-wrapper,
.product-card__inverted-price-wrapper,
.product-card__current-price-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: px-to-rem(10);
  a {
    text-decoration: underline;
  }

  @include MQ-up("laptop") {
    font-size: px-to-rem(12);
  }
}

.product-card__current-price,
.product-card__inverted-price,
.product-card__previous-price {
  margin: 0;
  color: var(--color-xxl-web-black);
  font-size: px-to-rem(20);
  font-family: var(--font-family-bold);
  line-height: px-to-rem(24);
  white-space: nowrap;

  @include MQ-up("laptop") {
    font-size: px-to-rem(34);
    line-height: px-to-rem(34);
  }
}

.product-card__current-price {
  @include discountedPrice;
}

$PRICE_TYPE_PROMOTION: "PROMOTION";

.product-card__inverted-price,
.product-card__previous-price {
  &[data-price-type="#{$PRICE_TYPE_PROMOTION}"] {
    color: var(--color-xxl-web-gray);
    text-decoration: line-through;
  }
}

.product-card__previous-price {
  text-decoration: line-through;
}

.product-card__inverted-price:not(
    [data-price-type="#{$PRICE_TYPE_PROMOTION}"]
  ) {
  color: var(--color-xxl-red);
}
